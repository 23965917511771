<template>
  <div class="payment-card">
    <h1 class="text-center page-title" style="color: hsla(0, 0%, 100%, .8); padding-top: 40px">Купон кодоо оруулах</h1>

    <InputText
        size="small"
        type="text"
        class="card-input"
        style="background: grey;color: white"
        v-model="code"
        placeholder="Картын дугаараа оруулна уу"
        autocomplete="off"
    />
    <ProgressSpinner v-if="btnLoading" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
    <Button v-else @click="submitForm">Илгээх</Button>

    <Message severity="error" v-if="error">{{ msg }}</Message>
    <Message severity="success" v-if="success">Таны эрх амжилттай сунгагдлаа</Message>
  </div>
</template>
<style>
.card-input::placeholder {
  color: lightgray;
}
</style>
<script>
import Message from 'primevue/message';
import api from "../../store/_boot/baseApi";

export default {
  name: "card.vue",
  components: {
    Message
  },
  data() {
    return {
      btnLoading: false,
      error: false,
      success: false,
      code: "",
      msg: ""
    }
  },
  methods: {
    submitForm() {
      this.success = false;
      this.error = false;
      if (this.code == '') {
        this.$toast.error('Картын дугаараа орууна уу');
        return;
      }

      this.btnLoading = true;
      let data = {
        val: this.code,
      };

      api.post("/payment/check", data).then(({data}) => {
        if (data.status) {
          this.success = true;
          this.error = false;
          this.msg = data.msg;
        } else {
          this.success = false;
          this.error = true;
          this.msg = data.msg;
        }
        this.btnLoading = false;
      }).catch(e => {
        console.log(e);
        this.btnLoading = false;
        this.success = false;
        this.error = true;
        this.msg = 'Алдаа гарлаа';
      })
    },
  }
}
</script>
